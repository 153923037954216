import request from '@/utils/request'

//获取banner列表
export function getBannerList (data) {
    return request({
        url: '/customer-service/cateringBanner/queryCateringBanner',
        method: 'post',
        data
    })
}

//banner上下架
export function bannerUpDownShelf (id, status) {
    return request({
        url: "/customer-service/cateringBanner/upOrDown?id=" +id + "&isUp=" + status,
        method: 'post'
    })
}

//根据id获取banner详情
export function getBannerDetail (id) {
    return request({
        url: "/customer-service/cateringBanner/queryCateringBannerDetail?id=" +id,
        method: 'get'
    })
}

//删除banner
export function deleteBanner (id) {
    return request({
        url: "/customer-service/cateringBanner/delCateringBanner?id=" + id,
        method: 'delete'
    })
}

//添加、编辑banner提交
export function subBannerForm (data, type) { //type 1 编辑 0 新增 
    return request({
        url: type ? "/customer-service/cateringBanner/updateCateringBanner" : "/customer-service/cateringBanner/addCateringBanner",
        method: 'post',
        data
    })
}

