<template>
  <div>
    <!-- 顶部搜索栏 -->
    <el-row>
    </el-row>
    <!-- 表格内容 -->
    <el-row class="mt-24">
      <el-col el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container">
     

          <el-tabs v-model="storeId" @tab-click="search" class='mt-20'>
            <el-tab-pane
              :label="item.storeName"
              :name="item.id+''"
              v-for="(item,index) in storeList"
              :key="index"
            >
            </el-tab-pane>
          </el-tabs>

     <!-- 操作栏 -->
          <div class="flx-row ali-c js-btw mt-20">
            <div class="flx-row ali-c">
              <el-button class="ml-15" type="primary" @click="showBannerDialog('add')">新增图片</el-button>
            </div>
          </div>

          <!-- 表格 -->
          <el-table class="mt-24 member-table" :data="tableData">
            <el-table-column label="图片" prop="picture">
              <template slot-scope="scope">
                <el-avatar
                  :src="scope.row.picture"
                  shape="square"
                  alt="加载失败"
                  :key="Math.random()"
                ></el-avatar>
              </template>
            </el-table-column>
            <el-table-column label="名称" prop="bannerName"></el-table-column>
            <el-table-column label="跳转" prop="merchantName"></el-table-column>
            <el-table-column label="状态">
              <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.status"
                  :active-value="1"
                  :inactive-value="0"
                  active-text="上架"
                  inactive-text="下架"
                  @change="upDownShelf($event, scope.row.id)"
                ></el-switch>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <div class="flx-row ali-c">
                  <span @click="showBannerDialog('edit', scope.row.id)" class="tip-text-edit">
                    <i class="el-icon-edit-outline"></i>
                    编辑
                  </span>
                  <span class="ml-10 tip-text-delete" @click="deleteBannerPic(scope.row.id)">
                    <i class="el-icon-delete"></i>
                    删除
                  </span>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <Pagination :total="total" :pageNum="pageNum" :pageSize="pageSize" :pageSizes="pageSizes" @changePage="search" @syncPageData="syncPageData"></Pagination>
        </div>
      </el-col>
    </el-row>
    <el-dialog :visible.sync="showBanner" :title="opeType=='add'?'添加图片':'编辑图片'" @close="clearDialog">
      <div class="pad-container">
        <el-form
          class="mt-24"
          label-width="100px"
          :model="banner_form"
          :rules="rules"
          ref="banner_form"
        >
          <el-form-item label="名称" prop="bannerName">
            <el-input
              placeholder="请输入图片名称"
              v-model="banner_form.bannerName"
              class="w-100"
            ></el-input>
          </el-form-item>
          <el-form-item label="添加图片" prop="picture">
            <UploadPics :uploadType="'main'" :imgLimit="1" :imgSize="'750*450'" :imgsPreview="banner_form.picture" @getImgData="getImgData" v-if='showBanner' />
          </el-form-item>
          <el-form-item label="跳转路径" prop="isTurn">
            <el-radio v-model="banner_form.isTurn" :label="1">跳转</el-radio>
            <el-radio v-model="banner_form.isTurn" :label="0">不跳转</el-radio>
          </el-form-item>
          <el-form-item label="商户选择" prop="merchantId" v-if="banner_form.isTurn==1 && storeId">
            <el-select
              placeholder="请选择商户"
              v-model="banner_form.merchantId"
              filterable
              class="w-100"
            >
              <el-option
                v-for="(item, index) in merchantList"
                :key="index"
                :label="item.merchantName"
                :value="item.merchantId"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="flx-row ali-c js-fe mt-50">
          <el-button plain size="small" class="form-button-w70" @click="showBanner=false">取消</el-button>
          <el-button class="form-button-w70" size="small" type="primary" @click="submit">确定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getStoreList, getCateringMerchantByStoreId} from "@/api/online_department_store/commodity_manage"
import Pagination from "@/components/Pagination/index"
import UploadPics from "@/components/UploadPics"
import request from "@/utils/request"
import { getBannerList, bannerUpDownShelf, getBannerDetail, deleteBanner, subBannerForm } from "@/api/repast/banner_config"
export default {
  name: "miniprogram_banner",
  components: {
    Pagination,
    UploadPics
  },
  data() {
    return {
      // 顶部搜索栏数据
      storeId: "",
      // 会员表格数据内容
      tableData: [],
      showBanner: false,
      opeType: "",
      bannerId: "",
      banner_form: {
        bannerName: "",
        picture: "",
        isTurn: null,
        merchantId: ""
      },
      rules: {
        bannerName: [{ required: true, message: "名称不能为空", trigger: "blur" }],
        picture: [{ required: true, message: "图片不能为空" }],
        isTurn: [{ required: true}],
        merchantId: [{ required: true, message: "商户不能为空"}],
      },
      storeList: [],
      merchantList: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 50, 100],
    };
  },
  mounted() {
    this.getStores()
  },
  methods: {
    // 搜索事件
    search(type) {
      if(type == 1){ //清空页数
        this.pageNum = 1
        this.pageSize = 10
      }

      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        storeId: this.storeId
      }

      getBannerList(params).then(res => {
        if(res.data.code == 200) {
          this.total = res.data.body.total
          this.tableData = res.data.body.list
          this.tableData.map(item => {
            item.picture = this.picsPath(item.picture)
          })
        }
      })
    },
    // 重置搜索关键词
    reset() {
      this.storeId = this.storeList[0].id.toString();
      this.search(1)
    },
    syncPageData(data) {
      this.pageNum = data.num
      this.pageSize = data.size
    },
    // 获取门店列表
    getStores() {
      getStoreList().then(res => {
        this.storeList = res.data.body.list;
        this.storeId = this.storeList[0].id.toString();
        this.search(1)
      })
    },
    //上下架
    upDownShelf(e, id){
      bannerUpDownShelf(id, e).then(res => {
        if(res.data.code == 200) {
          this.$message({
            message: "操作成功",
            type: "success"
          })
        }
      })
    },
    //获取单个Banner
    getBanner(id) {
      getBannerDetail(id).then(res => {
        this.banner_form = res.data.body
      })
    },
    showBannerDialog(type, id) { //打开新增、编辑弹窗
      this.showBanner = true;
      this.opeType = type
      if(id != undefined && id != 'undefined') {
        this.getBanner(id)
      }
         getCateringMerchantByStoreId( this.storeId).then(res => {
        // console.log(res.data)
          this.merchantList = res.data.body;
      });
    },
    clearDialog() { //关闭弹窗清空数据
      this.banner_form = {
        bannerName: "",
        picture: "",
        isTurn: null,
        storeId: "",
        merchantId: ""
      }
    },
    deleteBannerPic(id) {
      this.$confirm("此操作将永久删除该Banner, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      })
        .then(() => {
          deleteBanner(id).then(res => {
            if(res.data.code == 200){
              this.$message({
                type: "success",
                message: "删除成功!"
              });
              this.search()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    getImgData(imgData, type) {
      if(type == 'main') this.banner_form.picture = imgData.mainImg
    },
    submit() {
      this.$refs['banner_form'].validate(valid => {
        if(!valid) {
          this.$message({
            message: "表单信息不完整（有误）",
            type: 'warning'
          })
          return
        }
        this.submitForm()
      })
    },
    submitForm(){
      let data = this.banner_form;
      // console.log(data)
      data.storeId = this.storeId;
      subBannerForm(data, this.opeType == "edit"? 1:0).then(res => {
        if(res.data.code == 200) {
          this.$message({
            message: "操作成功",
            type: "success"
          })
          this.showBanner = false
          this.clearDialog()
          this.search()
        }
      })
    }
  }
};
</script>
<style lang="scss" scoped>
.member-table {
  .circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
}
.tip-text-edit {
  color: #409eff;
  cursor: pointer;
}
</style>
